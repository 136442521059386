.dashboard {
  display: grid;
  grid-template-columns: 1fr;
}
.dashboardPage {
  width: 100vw;
  margin: 0 auto;
}

/*
  @media (min-width: 992px) {
    .dashboard {
      grid-template-columns: auto 1fr;
    }
    .dashboard-page {
      width: 100%;
    }
  }
  */
