.myContainer {
  position: relative;
  border: 1px solid blueviolet;
  width: 302px; /* width of the pitch image we contain, plus the border */
  text-align: center;
  margin: auto;
}

/* Style for the vertical line */
.vertical::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%; /* Position the line in the center horizontally */
  width: 2px; /* Adjust the width of the line as needed */
  background-color: #000; /* Line color */
  transform: translateX(-50%); /* Center the line on the vertical axis */
  z-index: -1;
}

.notesareaDiv {
  width: 300px;
  height: 50px;
  overflow: hidden; /* Hide the scrollbar */
  scroll-behavior: smooth;
  background-color: #eeee;
}

.scrollableTextarea {
  background-color: rgb(206, 237, 206);
  color: 'green';
  width: 300px; /* Set the desired width of the textarea */
  height: 2em; /* Set the desired height of the textarea */
  overflow: hidden; /* Hide the scrollbar */ /*  white-space: nowrap; /* Prevent text from wrapping */
  white-space: nowrap;
}

.scrollableNotesImage {
  height: 50px;
  opacity: 0.45;
}
