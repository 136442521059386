.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #16a34a;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #16a34a;
  cursor: pointer;
}

.sliderDisabled {
  background: #cccccc !important;
  cursor: not-allowed !important;
}

.sliderDisabled:hover {
  opacity: 0.7 !important;
  cursor: not-allowed !important;
}

.sliderDisabled::-webkit-slider-thumb {
  background: #cccccc !important;
  cursor: not-allowed !important;
}

.sliderDisabled::-moz-range-thumb {
  background: #cccccc !important;
  cursor: not-allowed !important;
}
